<template>
  <div>
    <!-- 体围数据 -->
    <div class="comparison-list-box1" v-for="(item, index) in isRoundness" :key="index">
      <p class="item-lang">{{ item.name }}</p>
      <div class="comparison-list">
        <div class="comparison-list-first">
          <div v-if="item.lastWidth != 0">
            <span :class="qkStatus?'comparison-list-span-qk':'comparison-list-span'" v-if="unit === 'imperial'">{{ item.lastWidth }}
            </span>
            <span :class="qkStatus?'comparison-list-span-qk':'comparison-list-span'" v-else>{{ toDecimal(item.lastWidth * 2.54, 1) }}
            </span>
            <span>{{ $i18n.locale === 'ar-AR' ?  unit === "imperial" ? "بوصة" : "سم" : unit === "imperial" ? "Inches" : "cm" }}</span>
          </div>
          <div v-else>
            <span class="comparison-list-none">-- </span>
            <span>{{ $i18n.locale === 'ar-AR' ?  unit === "imperial" ? "بوصة" : "سم" : unit === "imperial" ? "Inches" : "cm" }}</span>
          </div>
        </div>
        <!-- 体围数据数据对比 -->
        <div class="comparison-list-second">
          <div v-if="item.lastWidth != 0">
            <img v-if="item.width - item.lastWidth == 0" src="@/assets/images/icon1_just.png" alt="" />
            <img v-else-if="item.width - item.lastWidth > 0" src="@/assets/images/icon1_rise.png" alt="" />
            <img v-else src="@/assets/images/icon1_decline.png" alt="" />
            <span v-if="unit === 'imperial'">
             {{
                Math.abs((item.width - item.lastWidth).toFixed(1)) }}
            </span>
            <span v-else>
             {{
                Math.abs((
                  toDecimal(item.width * 2.54, 1) - toDecimal(item.lastWidth * 2.54, 1)
                ).toFixed(1))
              }}
            </span>
          </div>
          <div v-else>
            <span class="comparison-none"> -- </span>
          </div>
        </div>
        <!-- 体围数据最新数据 -->
        <div class="comparison-list-first">
          <span v-if="item.width">
            <span :class="qkStatus?'comparison-list-span-qk':'comparison-list-span'" v-if="unit === 'imperial'">{{
              item.width
            }}</span>
            <span :class="qkStatus?'comparison-list-span-qk':'comparison-list-span'" v-else>{{
              toDecimal(item.width * 2.54, 1)
            }}</span>
          </span>
          <span v-else class="comparison-list-span1" style="color: #8aa3be"> -- </span>
          <span>{{ $i18n.locale === 'ar-AR' ?  unit === "imperial" ? "بوصة" : "سم" : unit === "imperial" ? "Inches" : "cm" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { _toDecimal } from "@/assets/js/util.js";
export default {
  props: {
    isRoundness: Array,
  },
  data() {
    return {
      unit: window.localStorage.getItem("unit"),
      qkStatus: false
    };
  },
  mounted() { 
    var userAgent = navigator.userAgent;
        if(userAgent.indexOf('Quark')>-1){
            this.qkStatus = true
        }
  },
  methods: {
    toDecimal(num, digit) {
      return _toDecimal(num, digit);
    },
  },
};
</script>
<style lang="less" scoped>
.comparison-list-box1 {
  position: relative;
  margin: 0 10px;
  overflow: hidden;

  p {
    width: 215px;
    margin: 12px auto 4px;
    font-size: 15px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ffffff;
    opacity: 0.2;
  }
}

.comparison-list {
  //   min-height: 70px;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  position: relative;

  .comparison-list-first {
    width: 33.33%;

    span {
      display: inline-block;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8aa3be;
      line-height: 12px;
    }

    .comparison-list-span {
      background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    .comparison-list-span-qk{
      color:#009fe8;
      display: inline-block;
    }

    .comparison-list-span1 {
      // line-height: 70px;
      font-size: 22px;
    }

    .comparison-list-none,
    .comparison-list-span {
      display: inline-block;
      font-size: 22px;
      font-family: AppleSystemUIFont;
      line-height: 22px;
      // line-height: 70px;
    }
  }

  .comparison-list-second {
    // margin-top: 17px;
    font-size: 15px;
    font-family: AppleSystemUIFont;
    color: #ffffff;
    line-height: 15px;
    width: 33.33%;

    div {
      margin-top: 4px;

      img {
        display: inline-block;
        margin-top: 2px;
        width: 14px;
        height: 14px;
        position: relative;
        top: 2px;
      }

      span {
        font-size: 13px;
        font-family: AppleSystemUIFont;
        color: #8aa3be;
        line-height: 13px;
      }
    }

    .comparison-none {
      line-height: 20px;
    }
  }
}
.ar-AR{
  .comparison-list-first{
    direction: rtl;
  }
}
</style>

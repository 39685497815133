<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-07-04 17:19:10
 * @LastEditors: liyunfei12354 3090384495@qq.com
 * @LastEditTime: 2024-07-18 15:47:12
-->
<template>
    <!-- 体态数据 -->
    <div :class="['thermal-item', $i18n.locale]">
        <div class="data-list">
            <div class="heard">
                <i>|</i>
                <span class="posture-name"
                    >{{ item.name
                    }}<img
                        class="help-icon"
                        src="../../assets/images/report/help.png"
                        alt
                        @click.prevent="handelHelp(item.name)"
                /></span>
                <!-- <img class="help-icon" src="../../assets/images/report/help.png" alt
                    @click.prevent="handelHelp(item.name)" /> -->
                <!-- <em></em> -->
            </div>
            <div class="shape-list">
                <span class="title">{{ $t('shape.item.deviate') }}</span>
                <div alt="" class="symbol-ico"></div>
                <span
                    :class="['val-list', { 'val-color': item.status !== $t('shape.item.normal') }]"
                    v-if="unit === 'metric'"
                    >{{ item.val | onHandler }}</span
                >
                <span :class="['val-list', { 'val-color': item.status !== $t('shape.item.normal') }]" v-else>{{
                    item.val | onHandler
                }}</span>
                <div alt="" class="symbol-ico"></div>

                <span class="shape-status">
                    <span :class="['status', { 'status-color': item.status !== $t('shape.item.normal') }]">{{
                        item.status
                    }}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { _toDecimal } from '@/assets/js/util.js'
export default {
    props: {
        item: Object,
        isLeg: Boolean,
    },
    filters: {
        onHandler(value) {
            if (value.indexOf('undefined') > -1 || value.indexOf('NaN') > -1) {
                return '-'
            } else {
                return value
            }
        },
    },
    data() {
        return {
            unit: window.localStorage.getItem('unit'),
        }
    },
    mounted () {
        console.log(this.item,'>>>aa')
    },
    methods: {
        handelHelp(name) {
            console.log(`当前体态类型${JSON.stringify(JSON.stringify(this.item))}`)
            this.$emit('handelHelp', name)
        },
        toDecimal(num, digit) {
            return _toDecimal(num, digit)
        },
    },
}
</script>
<style lang="less" scoped>
.data-list {
    width: 351px;
    min-height: 92px;
    height: fit-content;
    text-align: left;
    background: url(../../assets/images/report/bj31.png) center no-repeat;
    background-size: 100% 100%;
    margin-bottom: 10px;
    padding-bottom: 10px;

    .heard {
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        padding-right: 10px;
        align-items: center;

        .posture-name {
            font-size: 12px;
            color: #ffffff;
        }

        .help-icon {
            width: 10px;
            height: 10px;
            margin-left: 5px;
        }
    }

    i {
        font-style: normal;
        margin: 10px 10px;
        background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .shape-list {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        .title {
            font-size: 12px;
            color: #8aa3be;
            width: 76px;
            // padding-left: 28px;
        }

        .symbol-ico {
            min-width: 10px;
            height: 2px;
            // margin-left: 16px;
            background: url('../../assets/images/report/symbol_ico.png') repeat-x;
            background-size: cover;
        }

        img {
            width: 46px;
            height: 2px;
            margin: 0px 16px 3px 16px;
        }

        .val-list {
            display: inline-block;
            width: 70px;
            height: 22px;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            background-color: #33c070;
            border-radius: 10px;
        }

        .status {
            font-size: 12px;
            color: #33c070;
        }

        .status-color {
            color: #e64444;
        }

        .val-color {
            background-color: #cb4745;
        }

        .shape-status {
            // display: inline-flex;
            // align-items: center;
            // // position: absolute;
            // // right: 40px;
            text-align: left;
            // margin-left: 10px;
            width: 76px;
            // height: 24px;
            // line-height: 12px;
        }
    }
}
.ar-AR {
    .heard {
        img {
            margin-right: 6px;
        }
    }
    .shape-list {
        .shape-status {
            width: 100px;
        }
        .val-list{
            color:white;
        }
    }
}
</style>


<template>
    <div :class="['segment-info', $i18n.locale]">
        <mt-navbar v-model="selected">
            <mt-tab-item id="fat" @click.native="selectSegmentFat()">
                {{ $t('mass.segment.fat') }}
                <div :class="{ 'line': segmentStatus }"></div>
            </mt-tab-item>
            <span class="lines"></span>
            <mt-tab-item id="muscle" @click.native="selectSegmentMuscle()">
                {{ $t('mass.segment.muscle') }}
                <div :class="{ 'line': !segmentStatus }"></div>
            </mt-tab-item>
        </mt-navbar>
        <mt-tab-container v-model="selected" class="content">
            <mt-tab-container-item id="fat">
                <img src="@/assets/images/male.svg" alt="节段分布" />
                <div class="direction">
                    <span>{{ $t('mass.segment.left') }}</span>
                    <span>{{ $t('mass.segment.right') }}</span>
                </div>
                <div class="status" v-if="Object.keys(segment.fat).length">
                    <div class="left-up">
                        <span class="val">
                            {{ toDecimal(segment.fat.BFMRA.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.fat.BFMRA.status) }}
                        </span>
                    </div>
                    <div class="right-up">
                        <span class="val">
                            {{ toDecimal(segment.fat.BFMLA.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.fat.BFMLA.status) }}
                        </span>
                    </div>
                    <div class="center">
                        <span class="val">
                            {{ toDecimal(segment.fat.BFMTR.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.fat.BFMTR.status) }}
                        </span>
                    </div>
                    <div class="left-down">
                        <span class="val">
                            {{ toDecimal(segment.fat.BFMRL.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.fat.BFMRL.status) }}
                        </span>
                    </div>
                    <div class="right-down">
                        <span class="val">
                            {{ toDecimal(segment.fat.BFMLL.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.fat.BFMLL.status) }}
                        </span>
                    </div>
                </div>
            </mt-tab-container-item>
            <mt-tab-container-item id="muscle">
                <img src="@/assets/images/male.svg" alt="节段分布" />
                <div class="direction">
                    <span>{{ $t('mass.segment.left') }}</span>
                    <span>{{ $t('mass.segment.right') }}</span>
                </div>
                <div class="status" v-if="Object.keys(segment.muscle).length">
                    <div class="left-up">
                        <span class="val">
                            {{ toDecimal(segment.muscle.LMRA.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.muscle.LMRA.status) }}
                        </span>
                    </div>
                    <div class="right-up">
                        <span class="val">
                            {{ toDecimal(segment.muscle.LMLA.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.muscle.LMLA.status) }}
                        </span>
                    </div>
                    <div class="center">
                        <span class="val">
                            {{ toDecimal(segment.muscle.LMTR.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.muscle.LMTR.status) }}
                        </span>
                    </div>
                    <div class="left-down">
                        <span class="val">
                            {{ toDecimal(segment.muscle.LMRL.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.muscle.LMRL.status) }}
                        </span>
                    </div>
                    <div class="right-down">
                        <span class="val">
                            {{ toDecimal(segment.muscle.LMLL.v, 2) }}
                            <span class="unit">{{ $i18n.locale==='ar-AR'? ( unit === 'imperial' ? 'رطل' : 'كجم' ) :$i18n.locale==='el-GR'? ( unit === 'imperial' ? 'lbs' : 'κιλά' ) : unit === 'imperial' ? 'lbs' : 'kg' }}</span>
                        </span>
                        <span class="msg">
                            {{ toStatus(segment.muscle.LMLL.status) }}
                        </span>
                    </div>
                </div>
            </mt-tab-container-item>
        </mt-tab-container>
    </div>
</template>
<script>
import { _toDecimal } from '@/assets/js/util.js'
import { bmSegInfo } from '@/assets/js/apolloGql.js'
export default {
    name: 'SegmentData',
    props: {
        newMassInfo: Object
    },
    data() {
        return {
            selected: 'fat',
            segment: {
                fat: {},
                muscle: {}
            },
            unit: window.localStorage.getItem('unit'),
            segmentStatus: true
        }
    },
    created() {
        this.findBmSegInfo()
    },
    methods: {
        selectSegmentFat() {
            this.segmentStatus = true
        },
        selectSegmentMuscle() {
            this.segmentStatus = false
        },
        // 身体成分节段信息
        findBmSegInfo() {
            this.$apollo
                .query({
                    query: bmSegInfo,
                    variables: {
                        scanId: this.newMassInfo.scanId
                    }
                })
                .then(res => {
                    const data = res.data.bmSegInfo
                    if (data && data.code === 200) {
                        // 节段脂肪
                        this.segment.fat = data.data.bmSegBFM
                        // 节段肌肉
                        this.segment.muscle = data.data.bmSegLM
                        // 状态: 1 低，２正常，３高
                    }
                })
        },
        toStatus(status) {
            return status === 1 ? this.$t('mass.segment.lowStandard') : status === 3 ? this.$t('mass.segment.superStandard') : this.$t('mass.segment.standard')
        },
        toDecimal(num, digit) {
            if (this.unit === 'imperial') {
                return _toDecimal(num, digit)
            } else {
                return _toDecimal(num * 0.45359237, digit)
            }
        },
    }
}
</script>
<style lang="less" scoped>

.segment-info {
    margin-top: 24px;

    /deep/.mint-navbar {
        // width: 308px;
        width: 180px;
        margin: 0 auto;
        height: 35px;
        background: #121423;
        border-radius: 4px;
        background-color: transparent;

        .lines {
            height: 50%;
            color: #373955;
            border-left: 1px solid #373955;
            margin: 8px 25px 0 25px !important;
        }

        .mint-tab-item {
            position: relative;
            padding: 0;
            margin: 2px;
            color: #8AA3BE;

            &.is-selected {
                display: inline-block;
                height: 56px;
                background: #121423;
                border: none;
                background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .mint-tab-item-label {
                width: 65px;
                font-weight: 400;
                font-size: 14px;
                line-height: 31px;
            }

            .line {
                position: absolute;
                width: 35%;
                margin-top: 4px;
                margin-left: 20px;
                height: 2px;
                background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
            }
        }
    }

    .content {
        position: relative;
        width: 346px;
        height: 172px;
        background: #27334c;
        border-radius: 4px;
        margin: 25px auto 40px;

        img {
            width: 140px;
            height: 146px;
            margin: 13px auto;
        }

        .direction {
            span {
                position: absolute;
                color: #9B9B9B;
                font-size: 16px;
                right: 16px;
                left: auto;
                bottom: 8px;

                &:first-of-type {
                    right: auto;
                    left: 16px;
                }
            }
        }

        .status {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            .right-up {
                position: absolute;
                text-align: right;
                top: 36px;
                right: 245px;
            }

            .left-up {
                position: absolute;
                text-align: left;
                top: 36px;
                left: 245px;
            }

            .center {
                position: absolute;
                top: 60px;
                left: 50%;
                transform: translateX(-50%);
            }

            .right-down {
                position: absolute;
                text-align: right;
                top: 120px;
                right: 185px;
            }

            .left-down {
                position: absolute;
                text-align: left;
                top: 120px;
                left: 185px;
            }

            span {
                display: block;
            }

            .val {
                width: 70px;
                height: 22px;
                line-height: 22px;
                text-align: center;
                font-size: 16px;
                font-weight: normal;
                color: #FFFFFF;
                background: url(../../assets/images/segment_bj.png) center no-repeat;
                background-size: 100% 100%;
            }

            .unit {
                display: inline-block;
                font-size: 10px;
            }

            .msg {
                font-size: 12px;
                font-weight: normal;
                color: #8AA3BE;
                text-align: center;

                img {
                    width: 12px;
                    height: auto;
                    margin: 0 0 1px 0;
                    vertical-align: sub;
                }
            }
        }
    }
}
&.id-ID{
     /deep/ .mint-tab-item-label {
                width: 100px !important;
            }
            /deep/.mint-navbar{
                width: 67% !important;
            }
            /deep/.line{
                width: 60% !important;
            }
}

&.en-US,
&.it-IT,
&.fr-FR,
&.ru-RU,
&.es-ES,
&.pt-PT,
&.tr-TR,
&.de-DE,
&.pt-PTO,
&.th-TH,
&.ar-AR,
&.el-GR {
    /deep/.mint-tab-item-label {
        width: 114px !important;
        height: 30px;
        line-height: 15px !important;
    }

    /deep/.mint-tab-item {
        .is-selected {
            height: 40px !important;
            margin-bottom: -3px;
        }
    }

    /deep/.mint-navbar {
        width: 75%;
    }

    .line {
        position: absolute;
        width: 35%;
        margin-top: 4px;
        margin-left: 6px;
        height: 2px;
        background: linear-gradient(90deg, #00E3C9 0%, #009FE8 100%);
    }

    .lines {
        height: 50%;
        color: #373955;
        border-left: 1px solid #373955;
        margin: 8px 40px 0 25px !important;
    }
}
&.pt-PTO,&.th-TH{
    .line{
        margin-left: 38px !important;
    }
}
&.ar-AR{
    .line{
        margin-right: 38px !important;
    }
    .segment-info{
        direction: ltr;

    }
    .left-up{
        direction: rtl;
    }
    .right-up{
        direction: rtl;
    }
    .center{
        direction: rtl;
    }
    .left-down{
        direction: rtl;
    }
    .right-down{
        direction: rtl;
    }
}
</style>

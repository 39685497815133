<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-07-07 17:22:50
 * @LastEditors: liutq
 * @LastEditTime: 2022-09-07 15:09:13
-->
<template>
    <div>
        <div class="neck-assess-data body-thermal-wrapper">
            <!-- 外展上举-左手 -->
            <neck-data-item v-if="shoulderInfo.leftAbuction" :item="shoulderInfo.leftAbuction"></neck-data-item>
            <!-- 外展上举-右手 -->
            <neck-data-item v-if="shoulderInfo.rightAbuction" :item="shoulderInfo.rightAbuction"></neck-data-item>
            <!-- 前屈上举-左手 -->
            <neck-data-item v-if="shoulderInfo.leftAntexion" :item="shoulderInfo.leftAntexion"></neck-data-item>
            <!-- 前屈上举-右手 -->
            <neck-data-item v-if="shoulderInfo.rightAntexion" :item="shoulderInfo.rightAntexion"></neck-data-item>
        </div>
    </div>
</template>
<script>
import { findBsShoulderConclusion } from '@/assets/js/apolloGql.js'
import { toDecimal2 } from '@/assets/js/util.js'
import NeckDataItem from '@/components/common/NeckDataItem'
export default {
    props:{
        massInfo:Object
    },
    components: {
        NeckDataItem,
    },
    data() {
        return {
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            popupAssess: false,
            body: {},
            form: {
                name: '',
                desc: '',
                advice: '',
                result: 0
            },
            shoulderInfo: {},
            conclusion: [],
            isShow: false,
            bsShoulderNoMeasure: false,
            loading: false,
        }
    },
    mounted() {
        this.findBsShoulderConclusion()
        if (this.modelInfo.shoulderEval === 0) {
            this.bsShoulderNoMeasure = true
        } else if (this.modelInfo.bsShoulderScanResult === 1) {
            this.findBsShoulderConclusion()
        } else if (!this.modelInfo.bsShoulderScanResult || this.modelInfo.bsShoulderScanResult === 0) {
            this.isShow = true
        }
        // this.$hmt.trackPageview('/tab/shoulder')
    },
    methods: {
        toDecimal(num, digit) {
            return toDecimal2(num, digit)
        },
        // 获取肩部评估结论
        findBsShoulderConclusion() {
            let shareScanId = window.localStorage.getItem("shareScanId")
            //  分享链接进入取分享报告ScanId
            let shareFlag = !JSON.parse(localStorage.getItem('share')) && shareScanId
            this.$apollo
                .query({
                    query: findBsShoulderConclusion,
                    variables: {
                        scanId: shareFlag ? shareScanId : this.massInfo.scanId,
                        scanTime: this.massInfo.createTime
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBsShoulderConclusion
                    this.loading = false
                    if (data && data.code === 200) {
                        this.shoulderInfo = data.data.shoulderInfo
                        this.conclusion = data.data.conclusion
                    } else {
                        this.isShow = true
                    }
                })
        }
    }
}
</script>
<style lang="less" scoped>
.detail-content {
    display: flex;
    justify-content: center;

    .neck-assessment-list {
        width: 100%;
    }
}
</style>


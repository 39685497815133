<!--
 * @Description: Description
 * @Author: zhaoqianqian
 * @Date: 2022-06-30 18:04:56
 * @LastEditors: liutq
 * @LastEditTime: 2024-10-17 11:14:45
-->
<template>
    <!-- 头部信息 -->
    <div class="header-info">
        <div :class="['nickname',$i18n.locale]">
            <div class="nick-name">
                <!-- 昵称 -->
                <span style="padding-top:19px">{{ memberInfo.name || fomatterIphone(memberInfo.mobile) || email }}</span>
                <img v-if="memberInfo.sex === 1" src="@/assets/images/report/nan.png" alt="">
                <img v-else-if="memberInfo.sex === 2" src="@/assets/images/report/nv.png" alt="">
            </div>
            <div :class="['info',$i18n.locale]">
                <div class="span">
                    <!-- 身高 -->
                    <span>{{ $t('userInfo.height') }}</span>
                    <span class="age height">{{ height }}</span>
                </div>

                <div class="span">
                    <!-- 年龄 -->
                    <span>{{ $t('userInfo.age') }}</span>
                    <span class="age">{{ memberInfo.age }} {{ $t('userInfo.unit') }}</span>
                </div>

                <div class="span">
                    <!-- 体重 -->
                    <span v-if="deviceType === 3">{{ $t('userInfo.weight') }}：</span>
                    <span v-if="deviceType === 3" class="weight">{{ weight }} </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getMemberInfo, findBmMassQuotaInfos } from '@/assets/js/apolloGql.js'
import { _toDecimal,_unitTranslation } from '@/assets/js/util.js'

export default {
    props: {
        massInfo: Object,
    },
    data() {
        return {
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            deviceType: JSON.parse(window.localStorage.getItem('deviceType')),
            unit: window.localStorage.getItem('unit'),
            memberInfo: {
                name: '',
                height: '',
                age: '',
                sex: null,
                mobile: ''
            },
            weight: '- -',
            email: window.localStorage.getItem('email'),
        }
    },
    computed: { 
        height() {
            const height = this.memberInfo.height
            if (height) {
                if (this.$i18n.locale === 'en-US' || this.$i18n.locale === 'ja-JP' || this.$i18n.locale === 'zh-CN' || this.$i18n.locale === 'tr-TR'  || this.$i18n.locale === 'de-DE' || this.$i18n.locale === 'id-ID' || this.$i18n.locale === 'pt-PTO' || this.$i18n.locale === 'th-TH' || this.$i18n.locale === 'ar-AR' || this.$i18n.locale === 'el-GR') {
                    if (this.unit === 'imperial') {
                        const heightFt = Math.floor(Math.round(height) / 12)
                        const heightIn = Math.floor(Math.round(height) % 12)
                        if (this.$i18n.locale === 'ar-AR'){
                            return `${heightFt} قدم. ${heightIn} بوصة.`
                        }
                        return `${heightFt} ft. ${heightIn} in.`
                    } else {
                        if (this.$i18n.locale === 'ar-AR'){
                            return `${Math.round(height * 2.54)} سم`
                        }
                        return `${Math.round(height * 2.54)} cm`
                    }
                } else {
                    if (this.$i18n.locale === 'ar-AR'){
                            return `${Math.round(height * 2.54)} سم`
                        }
                    return `${Math.round(height * 2.54)} cm`
                }
            } else {
                return ''
            }
        }
    },
    created() {
        // 获取用户信息
        this.getMemberInfo()
        if(this.massInfo && this.massInfo.scanId && this.deviceType === 3) {
            this.bmMassContrasInfo()
        }
    },
    methods: {
        // 格式化手机号显示
        fomatterIphone(str) {
            let result = str || ''
            const phoneLen = result.length
            if (phoneLen > 14) {
                result = result.substr(0, 7) + '****' + result.substr(phoneLen - 4)
            } else if (phoneLen === 14) {
                result = result.substr(0, 6) + '****' + result.substr(phoneLen - 4)
            } else if (phoneLen > 7) {
                result = result.substr(0, 5) + '*'.repeat(phoneLen - 7) + result.substr(phoneLen - 2)
            }
            return result
        },
        // 侧边栏 获取用户信息
        getMemberInfo() {
            this.$apollo
                .query({
                    query: getMemberInfo
                })
                .then(res => {
                    const data = res.data.getMemberInfo
                    if (data && data.code === 200) {
                        this.memberInfo = data.data
                        window.localStorage.setItem('memberInfo', JSON.stringify(data.data))
                    }
                })
        },
        bmMassContrasInfo() {
            this.$apollo
                .query({
                    query: findBmMassQuotaInfos,
                    variables: {
                        memberId: this.modelInfo.memberId,
                        scanId: this.massInfo.scanId,
                        tcScanId: '',
                        deviceType: this.deviceType
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBmMassQuotaInfos
                    if (data.code === 200) {
                      data.data.forEach((value) => {
                        if(value.order === -1){
                            return
                        }
                        if (value.dataColumn === 'WT') {
                            if (this.unit === 'metric' && value.unit === 'kg') {
                                if (value.latestData) { 
                                    this.weight = `${this.toDecimal(value.latestData.v, 1)} ${this.unitTranslation('kg')}`
                                    console.log(this.weight,'tizhong')
                                }
                            } 
                            if (this.unit === 'imperial' && value.unit === 'kg') {
                                // value.unit = 'lbs'
                                if(this.$i18n.locale==='ar-AR'){
                                    this.weight = `${ this.toDecimal2(value.latestData.v)} رطل`
                                }else{
                                    this.weight = `${ this.toDecimal2(value.latestData.v)} lbs`
                                }
                                
                            }
                        }
                      });
                        
                    }
                })
        },
        unitTranslation(unit){ 
            return _unitTranslation(unit, this.$i18n.locale) 
        },
        toDecimal(num, digit) {
            return _toDecimal(num * 0.45359237, digit)
        },
        toDecimal2(x, y) {
            let f = Math.round(x * 100) / 100
            let s = f.toString()
            let rs = s.indexOf('.')
            if (rs < 0) {
                rs = s.length
                s += '.'
            }
            while (s.length <= rs + y) {
                s += '0'
            }
            return s
        },
    }

}
</script>

<style lang="less" scoped>
.header-info {
    display: flex;
    align-items: center;
    width: 353px;
    // background-color: #303358;
    background: url(../../assets/images/report/bj.png) center no-repeat;
    background-size: 100% 100%;
    margin: 0px 15px 0 11px;
    padding-bottom: 15px;
    font-size: 12px;
    color: #fff;

    .nickname {
        width: 353px;
        height: 78px;
        text-align: left;
        .nick-name {
            margin: 0px 16px 12px 16px;
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
            margin-top: 3px;

            img {
                margin-left: 5px;
                width: 14px;
                height: 14px;
            }

            span {
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                max-width: 308px;
                height: auto;
                word-wrap: break-word;
                /*强制换行*/
                overflow: hidden;
                /*超出隐藏*/
                text-overflow: ellipsis;
                /*隐藏后添加省略号*/
                line-height: 16px;
            }
        }
        .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 15px;
            padding-right: 15px;

            .span {
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                max-width: 308px;
                height: auto;
                word-wrap: break-word;
                /*强制换行*/
                overflow: hidden;
                /*超出隐藏*/
                text-overflow: ellipsis;
                /*隐藏后添加省略号*/
                line-height: 16px;
                color: #8AA3BE;
            }
        }
    }

   

    .nickname>span {
        margin-left: 16px;
        display: initial;
        text-align: left;
        font-size: 14px;
        color: #8AA3BE;
    }
    .age {
        margin-left: 5px !important;
       // width: 110px !important;
        margin-right: 20px !important;
    }
   
    .height {
        margin-right: 25px !important;
    }

    .weight {

    }

}
&.tr-TR,
&.de-DE,
&.el-GR{
    .age {
        margin-left: 5px !important;
       // width: 110px !important;
        margin-right: 0px !important;
    }
   
    .height {
        margin-right: 0px !important;
    }
}
&.ar-AR{
    .nick-name{
        text-align: right;
        img{
            margin-right: 4px;
        }
    }
    .header-info{
           margin: 0px 11px 0 11px;
    }
    .info{
       .span .age{
        margin: 0 10px !important;
       }
    }

}
</style>


var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['thermal-item', _vm.$i18n.locale]},[_c('div',{staticClass:"data-list"},[_c('div',{staticClass:"contrast-box"},[(_vm.item.contrast !== null)?_c('div',{staticClass:"contrast"},[_c('span',{style:(_vm.$i18n.locale==='ar-AR'? {direction:_vm.rtl}:'')},[_c('span',[_vm._v(_vm._s(_vm.$t('shoulder.model.diff')))]),(_vm.item.contrast > 0)?_c('img',{attrs:{"src":require("@/assets/images/icon1_rise.png"),"alt":""}}):(_vm.item.contrast == null || _vm.item.contrast == 0)?_c('img',{attrs:{"src":require("@/assets/images/icon1_just.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/icon1_decline.png"),"alt":""}}),_c('span',{staticClass:"diff-val"},[_vm._v(_vm._s(_vm.item.contrast !== null ? _vm.item.contrast >= 0 ? `${_vm.toDecimal2(_vm.item.contrast, 1)}°` : `${_vm.toDecimal2(_vm.item.contrast, 1)}°` : `${_vm.toDecimal2(_vm.item.contrast, 1)}°`))])])]):_vm._e()]),_c('div',{staticClass:"heard"},[_c('i',[_vm._v("|")]),_c('span',[_vm._v(_vm._s(_vm.item.name))])]),_c('div',{staticClass:"shape-list"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('shoulder.model.val')))]),_c('img',{attrs:{"src":require("../../assets/images/report/symbol.png"),"alt":""}}),_c('span',{class:[
                'val-list',
                { 'val-color': _vm.item.conclusion && _vm.item.conclusion !== _vm.$t('shape.item.normal') },
            ]},[_vm._v(_vm._s(_vm.item.val === null || !_vm.item.val ? '--' : `${_vm.toDecimal2(_vm.item.val, 1)}°`))]),_c('img',{attrs:{"src":require("../../assets/images/report/symbol.png"),"alt":""}}),_c('span',{staticClass:"shoulder-status"},[_c('span',{class:[
                    'status',
                    { 'status-color': _vm.item.conclusion && _vm.item.conclusion !== _vm.$t('shape.item.normal') },
                ]},[_vm._v(" "+_vm._s(_vm.item.conclusion !== null ? _vm.item.conclusion : '--')+" ")])])]),(_vm.$i18n.locale==='ar-AR')?_c('div',{staticClass:"scope"},[_vm._v(_vm._s(_vm.$t('shoulder.model.scope'))+" "),_c('span',{staticClass:"scope",staticStyle:{"direction":"ltr","unicode-bidi":"bidi-override"}},[_vm._v(_vm._s(_vm.item.limit))])]):_c('div',{staticClass:"scope"},[_vm._v(_vm._s(_vm.$t('shoulder.model.scope'))+" "+_vm._s(_vm.item.limit))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <!--围度信息图例-->
    <div class="body-roundness" :class="[$i18n.locale,{ 'no-container-background': !isSupportWebgl.webgl }]">
        <div class="person-img" :class="{ 'person-img-width': modelInfo.bdaStatus !== 1 }" v-if="isSupportWebgl.webgl">
            <img class="measure-top" src="../../assets/images/girth/fill@2x.png" />
            <img class="measure-bottom" src="../../assets/images/girth/fill2@2x.png" alt />
            <div class="model-container">
                <measure-model v-if="dispalyModel" class="measure-model" ref="model" :tcScanId="tcScanIdCopt"
                    @statusDispaly="OnIsDispaly" :show-girth="true"></measure-model>
                <!-- 日期选择 -->
                <div class="data-time">
                    <date-select :type="2" :isCreateTime="isCreateTime" @girthContras="onGirthContrasInfo"></date-select>
                </div>
            </div>
        </div>
        <!-- 判断浏览器支持 -->
        <version-tip v-else :tipMsg="isSupportWebgl.tipMsg" :title="isSupportWebgl.title"
            msgStyle="msg-style"></version-tip>
        <template v-if="isSupportWebgl.webgl && dispalyModel">
            <div :key="index" :class="{ 'hidden-roundness': modelInfo.bdaStatus !== 1 }" v-for="(item, index) in roundness">
                <div class="right-roundness-slide" v-if="index === slideIndex">
                    <div class="roundness-data" v-if="isDispaly">
                        <p v-if="unit === 'imperial'">{{ toDecimal(item.lastWidth, 1) }}</p>
                        <p v-else>{{ toDecimal(item.lastWidth * 2.54, 1) }}</p>
                        <p class="p">{{ $i18n.locale === 'ar-AR' ?  unit === "imperial" ? "بوصة" : "سم" : unit === "imperial" ? "Inches" : "cm" }}</p>
                    </div>
                    <div class="model-right" v-if="isDispaly">
                        <p v-if="unit === 'imperial'">{{ toDecimal(item.width, 1) }}</p>
                        <p v-else>{{ toDecimal(item.width * 2.54, 1) }}</p>
                        <p class="p">{{ $i18n.locale === 'ar-AR' ?  unit === "imperial" ? "بوصة" : "سم" : unit === "imperial" ? "Inches" : "cm" }}</p>
                    </div>
                    <div :class="['roundness-data', isDispaly ? '' : 'model-style']" v-else>
                        <p v-if="unit === 'imperial'">{{ toDecimal(item.width, 1) }}</p>
                        <p v-else>{{ toDecimal(item.width * 2.54, 1) }}</p>
                        <p class="p">{{ $i18n.locale === 'ar-AR' ?  unit === "imperial" ? "بوصة" : "سم" : unit === "imperial" ? "Inches" : "cm" }}</p>
                    </div>
                </div>
            </div>
        </template>
        <!-- 数据对比 -->
        <div class="girth-box">
            <girth-data :isRoundness="roundness"></girth-data>
        </div>
    </div>
</template>

<script>
import { bmGirthInfo, findBsScanTrueHistory, bmGirthContrasInfo } from '@/assets/js/apolloGql.js'
import isSupportWebgl from '@/assets/js/webgl.js'
import { _toDecimal } from '@/assets/js/util.js'
import MeasureModel from '@/components/model/Measure'
import VersionTip from '@/components/model/VersionTip.vue'
import DateSelect from '@/components/common/DateSelect'
import GirthData from './GirthData.vue'
import { mapState } from 'vuex'
export default {
    props: {
        isCreateTime: String
    },
    components: {
        MeasureModel,
        VersionTip,
        DateSelect,
        GirthData
    },
    data() {
        return {
            isSupportWebgl,
            modelInfo: JSON.parse(window.localStorage.getItem('modelInfo')),
            roundness: [
                {
                    name: this.$t('girth.tpl[0].title'),
                    width: '0',
                    lastWidth: '0',
                    girth: {
                        delta: 0,
                        curveName: 'neck_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[1].title'),
                    width: '0',
                    lastWidth: '0',
                    girth: {
                        delta: 1,
                        curveName: 'left_upper_arm_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[2].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 2,
                        curveName: 'right_upper_arm_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[3].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 3,
                        curveName: 'bust_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[4].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 4,
                        curveName: 'waist_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[5].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 5,
                        curveName: 'mid_waist_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[15].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 15,
                        curveName: 'low_waist_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[6].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 6,
                        curveName: 'hip_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[7].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 7,
                        curveName: 'left_thigh_girth'
                    }
                },
                // {
                //     name: this.$t('girth.tpl[8].title'),
                //     width: '4.16',
                //     lastWidth: '0',
                //     girth: {
                //         delta: 8,
                //         curveName: 'left_mid_thigh_girth'
                //     }
                // },
                {
                    name: this.$t('girth.tpl[9].title'),
                    width: '0',
                    lastWidth: '0',
                    girth: {
                        delta: 9,
                        curveName: 'left_min_thigh_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[10].title'),
                    width:  '0',
                    lastWidth: '0',
                    girth: {
                        delta: 10,
                        curveName: 'right_thigh_girth'
                    }
                },
                // {
                //     name: this.$t('girth.tpl[11].title'),
                //     width: '4.16',
                //     lastWidth: '0',
                //     girth: {
                //         delta: 11,
                //         curveName: 'right_mid_thigh_girth'
                //     }
                // },
                {
                    name: this.$t('girth.tpl[12].title'),
                    width: '0',
                    lastWidth: '0',
                    girth: {
                        delta: 12,
                        curveName: 'right_min_thigh_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[13].title'),
                    width: '0',
                    lastWidth: '0',
                    girth: {
                        delta: 13,
                        curveName: 'left_calf_girth'
                    }
                },
                {
                    name: this.$t('girth.tpl[14].title'),
                    width: '0',
                    lastWidth: '0',
                    girth: {
                        delta: 14,
                        curveName: 'right_calf_girth'
                    }
                }
            ],
            slideIndex: 0,
            createTime: '.',
            historyDate: [], //历史数据
            scanId: '',
            unit: window.localStorage.getItem('unit'),
            isDispaly: true,
            dispalyModel: false,
            tcScanIdCopt: ''
        }
    },
    computed: {
        ...mapState(['historyScanId', 'historyData'])
    },
    mounted() {
        if (!this.historyScanId) {
            this.onGirthContrasInfo()
        }
        let shareScanId = window.localStorage.getItem("shareScanId")
            //  分享链接进入取分享报告ScanId
        let shareFlag = !JSON.parse(localStorage.getItem('share')) && shareScanId
        if(shareFlag){
            this.scanId = shareScanId
        }else if (this.historyScanId) {
            this.scanId = this.historyScanId
        } else {
            this.scanId = this.modelInfo.scanId
        }
        this.getBmGirthInfo()
        this.findBsScanTrueHistory()
    },
    methods: {
        OnIsDispaly(data) {
            if (data == 2) {
                this.isDispaly = true
            } else {
                this.isDispaly = false
            }
        },
        // 根据当前scanId获取围度数据
        getBmGirthInfo() {
            this.$apollo
                .query({
                    query: bmGirthInfo,
                    variables: {
                        scanId: this.scanId
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.bmGirthInfo
                    if (data && data.code === 200) {
                        this.$lodash.each(this.roundness, () => {
                            this.roundness[0].width = data.data.neckGirth
                            this.roundness[1].width = data.data.leftUpperArmGirth
                            this.roundness[2].width = data.data.rightUpperArmGirth
                            this.roundness[3].width = data.data.bustGirth
                            this.roundness[4].width = data.data.waistGirth
                            this.roundness[5].width = data.data.midWaistGirth
                            this.roundness[6].width = data.data.lowWaistGirth
                            this.roundness[7].width = data.data.hipGirth
                            this.roundness[8].width = data.data.leftThighGirth
                            // this.roundness[8].width = data.data.leftMidThighGirth
                            this.roundness[9].width = data.data.leftMinThighGirth
                            this.roundness[10].width = data.data.rightThighGirth
                            // this.roundness[11].width =  data.data.rightMidThighGirth
                            this.roundness[11].width = data.data.rightMinThighGirth
                            this.roundness[12].width = data.data.leftCalfGirth
                            this.roundness[13].width = data.data.rightCalfGirth
                        })
                    }
                })
        },
        // 获取测量对比的数据
        onGirthContrasInfo(tcScanId) {
            this.dispalyModel = false;
            if (tcScanId != null) {
                window.localStorage.setItem('tcScanId', tcScanId)
                this.tcScanIdCopt = this.historyData.scanId
            } else {
                window.localStorage.removeItem('tcScanId', tcScanId)
            }

            this.$apollo
                .query({
                    query: bmGirthContrasInfo,
                    variables: {
                        memberId: this.modelInfo.memberId,
                        scanId: this.scanId,
                        tcScanId: tcScanId
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    this.dispalyModel = true;
                    const data = res.data.bmGirthContrasInfo.data.contrastBmGirth
                    this.historyDate = []
                    if (data) {
                        this.$lodash.each(this.roundness, () => {
                            this.roundness[0].lastWidth = data.neckGirth
                            this.roundness[1].lastWidth = data.leftUpperArmGirth
                            this.roundness[2].lastWidth = data.rightUpperArmGirth
                            this.roundness[3].lastWidth = data.bustGirth
                            this.roundness[4].lastWidth = data.waistGirth
                            this.roundness[5].lastWidth = data.midWaistGirth
                            this.roundness[6].lastWidth = data.lowWaistGirth
                            this.roundness[7].lastWidth = data.hipGirth
                            this.roundness[8].lastWidth = data.leftThighGirth
                            // this.roundness[8].lastWidth = data.leftMidThighGirth
                            this.roundness[9].lastWidth = data.leftMinThighGirth
                            this.roundness[10].lastWidth = data.rightThighGirth
                            // this.roundness[11].lastWidth = data.rightMidThighGirth
                            this.roundness[11].lastWidth = data.rightMinThighGirth
                            this.roundness[12].lastWidth = data.leftCalfGirth
                            this.roundness[13].lastWidth = data.rightCalfGirth
                        })
                    } else {
                        this.$lodash.each(this.roundness, () => {
                            this.roundness[0].lastWidth = 0
                            this.roundness[1].lastWidth = 0
                            this.roundness[2].lastWidth = 0
                            this.roundness[3].lastWidth = 0
                            this.roundness[4].lastWidth = 0
                            this.roundness[5].lastWidth = 0
                            this.roundness[6].lastWidth = 0
                            this.roundness[7].lastWidth = 0
                            // this.roundness[8].lastWidth = 0
                            this.roundness[8].lastWidth = 0
                            this.roundness[9].lastWidth = 0
                            // this.roundness[11].lastWidth = 0
                            this.roundness[10].lastWidth = 0
                            this.roundness[11].lastWidth = 0
                            this.roundness[12].lastWidth = 0
                            this.roundness[13].lastWidth = 0
                        })
                    }
                })
        },
        // 获取历史记录
        findBsScanTrueHistory() {
            this.$apollo
                .query({
                    query: findBsScanTrueHistory,
                    variables: {
                        memberId: this.modelInfo.memberId,
                        scanTime: window.localStorage.getItem('createTime') ? JSON.parse(window.localStorage.getItem('createTime')) : this.modelInfo.createTime,
                        deviceType: JSON.parse(window.localStorage.getItem('deviceType'))
                    },
                    fetchPolicy: 'network-only'
                })
                .then(res => {
                    const data = res.data.findBsScanTrueHistory
                    this.historyDate = []
                    if (data && data.code === 200) {
                        for (let index = 0; index < data.data.length; index++) {
                            for (let aa = 0; aa < data.data[index].data.length; aa++) {
                                this.historyDate.push(data.data[index].data[aa])
                            }
                        }
                    }
                    this.$store.commit('setDataArray', this.historyDate)
                })
        },
        slideTop() {
            if (this.slideIndex === 0) {
                this.slideIndex = this.roundness.length - 1
            } else {
                this.slideIndex -= 1
            }
            this.$refs.model.selectCurve(this.roundness[this.slideIndex].girth)
        },
        slideBottom() {
            this.slideIndex += 1
            if (this.slideIndex === this.roundness.length) {
                this.slideIndex = 0
            }
            this.$refs.model.selectCurve(this.roundness[this.slideIndex].girth)
        },
        setSlideIdx(idx) {
            this.slideIndex = idx
        },
        toDecimal(num, digit) {
            return _toDecimal(num, digit)
        },
    }
}
</script>

<style lang="less" scoped>
.hidden-roundness {
    // display: none;
}

.body-roundness {
    background-image: url('../../assets/images/girth/Background.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    padding-top: 36px;
    margin: 12px;
    // display: flex;
    // justify-content: center;
    position: relative;
    height: auto;

    // 禁止部分安卓机下图片自动放大
    img {
        pointer-events: none;
    }

    .girth-box {
        // margin-top: 74px
    }

    .model-container {
        position: relative;
    }

    >div {
        // padding-top: 30px;

        .right-roundness-slide {
            background: none;
            position: absolute;
            top: 0px;
            right: 130px;

            .roundness-data {
                position: relative;
                top: 0px;
                right: 0px;
                background-image: url('../../assets/images/girth/jxing@2x.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 89px;
                height: 79px;
                text-align: center;
                font-size: 36px;
                padding-top: 28px;
                margin-bottom: 6px;

                p {
                    line-height: 15px;
                }

                p {
                    display: block;
                    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }

            .model-style {
                position: relative;
                top: 0px;
                right: -90px;
            }

            .model-right {
                position: relative;
                top: 0px;
                left: 0px;
                width: 89px;
                height: 79px;
                background-image: url(../../assets/images/girth/jxing2@2x.png);
                background-repeat: no-repeat;
                background-size: 100% 100%;
                text-align: center;
                // font-size: 36px;
                padding-top: 28px;

                p {
                    line-height: 15px;
                }

                p {
                    display: block;
                    background: linear-gradient(90deg, #00e3c9 0%, #009fe8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    >.person-img-width {
        width: 100% !important;
        background-position: center bottom !important;
    }

    >.person-img {
        // display: flex;
        // justify-content: center;
        // min-width: 215px;
        margin-bottom: 15px !important;
        // background-image: url(../../assets/images/report_moxing_bg.png);
        // background-size: 135px 57px;
        background-repeat: no-repeat;
        background-position: 58px bottom;
        margin: 0 auto;
        min-height: 300px;

        .measure-top {
            position: absolute;
            left: 8px;
            right: 8px;
            top: 8px;
            width: 96%;
        }

        .measure-bottom {
            position: absolute;
            top: 245px;
            left: 8px;
            right: 8px;
            width: 96%;
        }

        .data-time {
            position: absolute;
            bottom: -10px;
            right: 0;
            left: 0px;
            margin: auto 20px;
            height: 52px;
        }
    }

    .measure-model {
        padding-bottom: 30px;
        height: 300px;
    }
}
</style>

<style lang="less">
.body-roundness {
    .model-loading {
        top: -33px !important;
    }
}
&.ar-AR{
    
    .data-time{
        direction: ltr !important;
    }
    .girth-box{
        direction: ltr !important;
    }
    .p{
        font-size: 18px !important;
    }

}
</style>
